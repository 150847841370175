<template>
	<div class="landList">
		<!-- <keep-alive v-if="$route.meta.keepAlive"> -->
			<router-view></router-view>
		<!-- </keep-alive> -->
<!--		<router-view v-if="!$route.meta.keepAlive"></router-view>-->
	</div>
</template>

<script>
	export default {
		name: 'landList'
	}
</script>

<style scoped lang="scss">
	.landList {
		background-color: #f0f3f8;
		min-height: 100%;
	}
</style>
